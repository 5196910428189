import { GoogleLogin} from '@react-oauth/google'
import React, {useEffect, useState} from "react";
import axios from '../http-common';
import {isAxiosError} from 'axios';

type user_t = {
  "email": string,
  "nickname": string,
  "approved_nickname": boolean,
  "pending_review"?: boolean,
  "paid"?: boolean
}

export default function User(){
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [userInfo, setUserInfo] = useState({
    "email": "Loading email...",
    "nickname": "Loading nickname...", 
    "approved_nickname": true,
    "pending_review": true,
    "paid": false
  } as user_t);
  const [registerMode, setRegisterMode] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [userNickname, setUserNickname] = useState("Unnamed Tracker");
  useEffect(() => {
    axios.get("/users/info").then(res => {
      setLoggedIn(true);
      setUserInfo(res.data);
    }).catch(() => {
      setLoggedIn(false);
    })
  },[])
  if(loggedIn){
    return(
    <div style={{borderWidth: 1, borderColor: "white", padding: 10}}>
      <p>Logged in!</p>
      <p className='text-base'>Email: {userInfo.email} (only you can see this email)</p>
      <p className='text-base'>Nickname: {userInfo.nickname} {
        userInfo.pending_review
        ?
        "(nickname pending review)"
        :
        <>{!userInfo.approved_nickname && "(nickname rejected.)"}</>
      }</p>
    <p className='text-base'>{userInfo.paid ? "This is a premium account!" : "This is a normal account."}</p>
    </div>
    );
  }
  if(loginError !== ""){
    return(
      <div style={{borderWidth: 1, borderColor: "white", padding: 10}}>
        <p>Error received: {loginError}</p>
      </div>
    );
  }
  if(registerMode){
    return(
      <div style={{borderWidth: 1, borderColor: "white", padding: 10, margin: 10}}>
        <p>Register new account</p>
        <p className='text-base'>You've already signed into your email, provide an appropriate nickname to have other users call you! This is all you need to register.</p>
        <p className='text-base mt-6'>Nickname:</p>
        <input
          name={"nickname"}
          value={userNickname}
          onChange={event => {setUserNickname(event.target.value)}}
          className='m-4 text-black'
        />
        <button className='bg-slate-600 p-3' onClick={() => {
          axios.post("/users/", {"google_token": userToken, nickname: userNickname}).then(() => {
            setRegisterMode(false);
          })
        }}>
          <p>Register your email under nickname: "{userNickname}"</p>
        </button>
      </div>
    );
  }
  return(
    <div style={{borderWidth: 1, borderColor: "white", padding: 10, margin: 10}}>
      <p>Login/Register</p>
      <p className='text-base'>Connecting Apple and Google emails to a single account isn't supported yet, so please choose carefully! (Especially if you paid for the app!)</p>
      <GoogleLogin
        onSuccess={credendtialResponse => {
          axios.post("/users/login", {
            "google_token": credendtialResponse.credential
          }).then(() => {
            setLoggedIn(true);
          }).catch(e => {
            if(isAxiosError(e)){
              switch(e.response?.status){
                case 404: {
                  if(credendtialResponse.credential){
                    setRegisterMode(true);
                    setUserToken(credendtialResponse.credential);
                  }else{
                    setLoginError(JSON.stringify(e));
                  }
                }
              }
            }else{
              setLoginError(JSON.stringify(e));
            }
          });
        }}
        onError={() => {console.log("Google login error");}}
      />
      <div className='bg-white'><p className='text-sm text-black text-center mt-4 p-2'>Apple (Coming soon)</p></div>
      {
        loggedIn &&
        <p>Successfully logged in!</p>
      }
      {
        loginError !== "" &&
        <p>Error: {}</p>
      }
    </div>
  )
}
