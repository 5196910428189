import './App.css';
import List from './components/List.tsx';
import Edit from './components/Edit.tsx';
import User from './components/User.tsx';
import TuneDataService from './services/tune.service';
import ComposerDataService from './services/composer.service';
import React from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
//import axios from './http-common';
let tds = new TuneDataService();
let cds = new ComposerDataService();
function App() {
  const [tunes, setTunes] = React.useState(null);
  const [composers, setComposers] = React.useState(null);
  const [currentItem, setCurrentItem] = React.useState({});
  const [editing, setEditing] = React.useState(false);
  const [isComposer, setIsComposer] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const reloadTunes = (() => {
    tds.getAll().then((response) => {
      setTunes(response.data);
    });
  });
  const reloadComposers = (() => {
    cds.getAll().then((response) => {
      setComposers(response.data);
    });
  });
  React.useEffect(() => {
    reloadTunes();
    reloadComposers();
  }, []);
  if(tunes === null){
    return (
      <p>
      <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
      "Loading... (Refresh after 3 seconds)"
      </p>
    );
  }
  return (
    <header className='App-header w-screen overflow-x-scroll'>
    <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
    <GoogleOAuthProvider clientId="908442210037-ojtlrnapbllpfc2ln1ccmbq808ni8seh.apps.googleusercontent.com">
      <div>
        <a href='https://privacy.jhilla.org'>
          <button className='m-3 p-2 bg-slate-700 text-lg'>
            Privacy Policy
          </button>
        </a>
        <button className='m-3 p-2 bg-slate-700 text-lg' onClick={()=>{
          setCurrentItem({});
          setIsComposer(false);
          setEditing(true);
        }}>
          Add tune
        </button>
        <button className='m-3 p-2 bg-slate-700 text-lg' onClick={()=>{
          setCurrentItem({});
          setIsComposer(true);
          setEditing(true);
        }}>
          Add composer
        </button>
        <button className='m-3 p-2 bg-slate-700 text-lg' onClick={()=>{
          setLoginOpen(!loginOpen);
        }}>
          User
        </button>
        {
          loginOpen && 
          <User/>
        }
      </div>
      <div className='flex-row flex'>
        {(!editing || isComposer) && composers && <List
          list={composers}
          setCurrentTune={setCurrentItem}
          setEditing={setEditing}
          setIsComposer={setIsComposer}
          isComposerList={true}/>}
        {(!editing || !isComposer) && tunes && <List list={tunes}
          setCurrentTune={setCurrentItem}
          setIsComposer={setIsComposer}
          isComposerList={false}
          setEditing={setEditing}/>}
        { editing && 
          <Edit data={currentItem}
            setEditing={setEditing}
            tuneDataService={tds}
            composerDataService={cds}
            isComposer={isComposer}
            composers={composers}
          /> 
        }
      <div/>
      </div>
    </GoogleOAuthProvider>
    </header>
  );
}

export default App;
